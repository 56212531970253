<template>
  <div>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Microsoft Teams Integration</h1>
      <p>
        Use our Microsoft Teams integration to bring your step and fitness challenges to Microsoft Teams! Creating a challenge and inviting a team is a breeze and you can go live within a few minutes!
      </p>
      <p>
        Microsoft Teams integration is available as part of Free (up to 5 users) and <router-link to="/pricing">Pro plans</router-link>.
      </p>
      <h3>How it works</h3>
      <ol class="mb-4">
        <li><a href="https://appsource.microsoft.com/en-us/product/office/WA200004093" target="_blank">Add us to Microsoft Teams</a></li>
        <li>Add our <a href="https://appsource.microsoft.com/en-us/product/office/WA200004093" target="_blank">Microsoft Teams app</a> to a channel or chat in Microsoft Teams.</li>
        <li>Create a challenge using our 5-step wizard and publish your event when all is ready to go</li>
        <li>Add members to the challenge or simply import an Excel file with their information. They will receive a personal invite to join the challenge and download our <router-link to="/get">iPhone or Android app</router-link>.</li>
      </ol>
      <p>
        <strong>That's it, let's get moving!</strong>
      </p>
      <p>
        <v-btn outlined href="https://appsource.microsoft.com/en-us/product/office/WA200004093" target="_blank" x-large height="60px" style="line-height:60px;">
          <img height="40px" src="https://sodisp.imgix.net/web/microsoft-teams-logo-sm.png?h=40"/>
          Add To Teams
        </v-btn>
        <!-- TODO: Add To Teams Button here -->
      </p>

    </div>

    <div id="" class="mt-0 white--text">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <v-sheet :elevation="4" class="pa-4"><img src="https://sodisp.imgix.net/web/microsoft-teams/microsoft-teams-with-chrome.png?w=1080&fit=fillmax" style="max-width:100%;" /></v-sheet>
          </v-col>
        </v-row>
      </div>
    </div>

    <div>
      <h2 class="subheader mt-8">Seamless Integration with Microsoft Teams</h2>
      <v-row>
        <!-- <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" >
            <v-icon size="50" color="grey">fa-sync</v-icon>
            <h3 class="my-4">Member Sync</h3>
            <p>Inviting your team to a challenge has never been so simple: we automatically invite all members from your Microsoft Teams channel or chat.</p>
          </v-sheet>
        </v-col> -->
        <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" >
            <v-icon size="50" color="grey">fa-poll-people</v-icon>
            <h3 class="my-4">Leaderboards</h3>
            <p>View the leaderboards directly from the special CorporateFitness.app tab within Microsoft Teams.</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-sheet :elevation="1" class="text-center align-center pa-4" >
            <v-icon size="50" color="grey">fa-shoe-prints</v-icon>
            <h3 class="my-4">Step and Fitness Challenges</h3>
            <p>Create and manage your challenges right from within Microsoft Teams.</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" class="text-center align-center pa-4" to="/get">
            <v-icon size="50" color="grey">fa-mobile</v-icon>
            <h3 class="my-4">iPhone and Android app</h3>
            <p>Use our iPhone or Android app to track your progress.</p>
          </v-card>
        </v-col>
      </v-row>
      <h3>Secure and privacy friendly</h3>
      <p>
        We understand security and privacy are important to your organization and take these very seriously. We only have access to the (private or public) teams, channels, and chats where you decide to add our Microsoft Teams apps to. All data we store is encrypted at rest and encrypted in transit to ensure it stays secure at all time.
      </p>
      <p>
        Read our <router-link to="/privacypolicy">{{$t('account.register.terms_privacy_privacy')}}</router-link> and <router-link to="/termsofservice">{{$t('nav.footer.terms-of-service')}}</router-link> for all details.
      </p>
    </div>


    <div>
      <h2 class="subheader mt-8">Integration with all major fitness trackers</h2>
      <p>
        We offer native integration with all major fitness wearables for automatic upload of activities. This greatly improves the experience of your participants! <br/>
        The app contains basic fitness tracking features which makes it a great choice for participants who are not yet using another tracker!
      </p>
      <SectionIntegrations />
    </div>

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <!-- TODO: Add To Teams Button here -->
              <v-btn class="mt-n8" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      slashCommands: [
        { command: 'join', text: 'Get the personal join link for a member to join a challenge' },
        { command: 'status', text: 'Get the status of all connected events in a channel' },
        { command: 'connect', text: 'Connect an event to a challenge' },
        { command: 'sync', text: 'Force a sync of all members to connected event(s)' },
        { command: 'disconnect', text: 'Disconnect all events from the channel' },
        { command: 'login', text: 'Login to your profile' },
        { command: 'manage', text: 'Open the event manager to manage your event' },
        { command: 'help', text: 'Get help and tips' },
      ],
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>